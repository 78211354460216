exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-contact-index-tsx": () => import("./../../../src/templates/Contact/index.tsx" /* webpackChunkName: "component---src-templates-contact-index-tsx" */),
  "component---src-templates-exito-index-tsx": () => import("./../../../src/templates/Exito/index.tsx" /* webpackChunkName: "component---src-templates-exito-index-tsx" */),
  "component---src-templates-home-index-tsx": () => import("./../../../src/templates/Home/index.tsx" /* webpackChunkName: "component---src-templates-home-index-tsx" */),
  "component---src-templates-pi-caballito-index-tsx": () => import("./../../../src/templates/PICaballito/index.tsx" /* webpackChunkName: "component---src-templates-pi-caballito-index-tsx" */),
  "component---src-templates-pi-urquiza-index-tsx": () => import("./../../../src/templates/PIUrquiza/index.tsx" /* webpackChunkName: "component---src-templates-pi-urquiza-index-tsx" */),
  "component---src-templates-set-palermo-index-tsx": () => import("./../../../src/templates/SetPalermo/index.tsx" /* webpackChunkName: "component---src-templates-set-palermo-index-tsx" */)
}

